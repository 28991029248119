export const categories = [
  {
    name: 'Sci-Fi',
    id: 1,
  },
  {
    name: 'Komedia',
    id: 2,
  },
  {
    name: 'Dramat',
    id: 3,
  },
  {
    name: 'Kryminał',
    id: 4,
  },
  {
    name: 'Akcja',
    id: 5,
  },
  {
    name: 'Przygodowy',
    id: 6,
  },
];
